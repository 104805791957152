<template>

  <div class="logos theme-font">

    <div class="section">
      <img class="icon icon-white va-middle" src="/images/logo.svg" alt="Logo" style="height:120px;">
      <h1 class="name name-white va-middle">Swipewrap</h1>
    </div>

    <div class="header">
      <div class="header-content">
        <div class="section">
          <img class="icon icon-dark va-middle" src="/images/logo.svg" alt="Logo" style="height:120px;">
          <h1 class="name name-dark va-middle">Swipewrap</h1>
          <!-- **** slogan **** -->
          <br />
          <span class="slogan theme-font name-dark">A new era of gifting</span>
          <!-- ******** -->
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  export default {
  }
</script>

<style scoped>

  .section {
    padding: 200px;
    padding-left: 300px;
  }

  h1 {
    display: inline-block;
    font-size: 100px;
    font-weight: 400;
  }
  .slogan {
    font-size: 50px;
    margin-left: 97px;
    line-height: 40px;
  }

  .icon {
    margin-right: 24px;
  }

  .name-white {
    color: #2f2f2f;
  }

  .name-dark {
    color: white;
  }



  .header {
    background: linear-gradient(to bottom, #9C2B55, #CD4679 );
    /* background: linear-gradient(to bottom, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #fafafa ); */
    /* #CD4679 #88254A #B03060 #B03060 #D25A87 */
    /* background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px); */
  }
  .header-content {
    min-height: 100vh;
    padding-top: 70px;
    padding-bottom: 50px;
    background-image: repeating-linear-gradient(70deg, transparent, transparent 35px, rgba(255,255,255,.025) 35px, rgba(255,255,255,.025) 70px);
  }


</style>
